var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"app"}},[_c('v-app-bar',{attrs:{"app":"","height":_vm.$store.getters.socioAuth ? 80 : 120,"color":_vm.$store.getters.socioAuth ? 'primary darken-2' : 'coconut'}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[(_vm.$vuetify.breakpoint.mobile)?[_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{staticClass:"mx-2",attrs:{"active-class":"","to":{ name: 'HomeView' }}},[_c(_vm.$store.getters.socioAuth
                                        ? 'logo-aulexna-socios'
                                        : 'logo-aulexna-tertiary',{tag:"component"})],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"dark":_vm.$store.getters.socioAuth,"icon":"","x-large":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-menu")])],1)],1)]:[_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{staticClass:"mx-5",attrs:{"to":{ name: 'HomeView' }}},[_c(_vm.$store.getters.socioAuth
                                        ? 'logo-aulexna-socios'
                                        : 'logo-aulexna-tertiary',{tag:"component"})],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.$store.getters.socioAuth)?[_c('a',{staticClass:"h6 text-center surface--text",attrs:{"href":"https://m.berrly.com/aulexna/login","target":"_blank"}},[_vm._v("ZONA PRIVADA")]),_c('router-link',{staticClass:"mx-5 white--text",attrs:{"to":{ name: 'HomeView' }}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"margin-top":"-2.5px"},attrs:{"color":"white","size":"1.2rem"}},[_vm._v("mdi-exit-to-app")]),_c('span',{staticClass:"text-uppercase font-weight-regular"},[_vm._v("SALIR")])],1)]:_vm._l((_vm.menuToShow),function(item){return _c('router-link',{key:item.uuid,staticClass:"mx-3 black--text",class:{
                                    'surface--text': item.destacable
                                },attrs:{"to":{ name: item.pathName }}},[_c('span',{staticClass:"text-uppercase font-weight-regular",staticStyle:{"font-size":"1.1rem"}},[_vm._v(_vm._s(item.title))])])})],2)]],2)],1)],1),_c('v-navigation-drawer',{attrs:{"app":"","dark":"","color":"primary","temporary":"","width":"80%","mobile-breakpoint":"600"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"info--text text--accent-4"}},[_vm._l((_vm.menuToShow),function(item){return _c('v-list-item',{key:item.uuid},[_c('v-list-item-title',[_c('router-link',{staticClass:"h6 white--text",class:{ 'surface--text': item.destacable },attrs:{"to":{ name: item.pathName }}},[_vm._v(_vm._s(item.title))])],1)],1)}),(this.$store.getters.socioAuth)?[_c('v-list-item',[_c('v-list-item-title',[_c('a',{staticClass:"h6 text-center surface--text",attrs:{"href":"https://m.berrly.com/aulexna/login","target":"_blank"}},[_vm._v("Zona privada")])])],1)]:_vm._e(),(this.$store.getters.socioAuth)?[_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{staticClass:"h6 text-center surface--text",attrs:{"to":{ name: 'HomeView' }}},[_vm._v("Salir")])],1)],1)]:_vm._e()],2)],1)],1),_c('v-main',{staticClass:"secondary lighten-2"},[(_vm.$store.getters.socioAuth && !_vm.$vuetify.breakpoint.mobile)?[_c('v-container',{staticClass:"pa-0 fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-navigation-drawer',{attrs:{"absolute":"","clipped":"","left":"","color":"secondary"},model:{value:(_vm.$store.getters.socioAuth),callback:function ($$v) {_vm.$set(_vm.$store.getters, "socioAuth", $$v)},expression:"$store.getters.socioAuth"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"primary--text"}},_vm._l((_vm.menuItemsPrivados.filter(
                                            (e) => !e.destacable
                                        )),function(item){return _c('v-list-item',{key:item.uuid,class:{
                                            'background lighten-3':
                                                item.destacable
                                        },attrs:{"to":{ name: item.pathName }}},[_c('v-list-item-avatar',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"size":"1.3rem","color":"primary"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{staticClass:"text-left"},[_c('router-link',{staticClass:"h6",class:{
                                                    'white--text':
                                                        item.destacable
                                                },attrs:{"to":{
                                                    name: item.pathName
                                                }}},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('router-view')],1)],1)],1)]:[_c('v-container',{class:{
                    'pa-0':
                        _vm.$route.name === 'HomeView' ||
                        _vm.$vuetify.breakpoint.mobile
                },attrs:{"fluid":""}},[_c('router-view')],1)]],2),_c('v-footer',{attrs:{"padless":""}},[_c('v-card',{staticClass:"px-10 primary text-center",attrs:{"flat":"","tile":"","width":"100%"}},[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"start","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('logo-aulexna-secondary'),_c('p',{staticClass:"text-left white--text"},[_vm._v(" C/. Sadar, s/n. Edif. El Sario (UPNA). Ofc. 231 31006 Pamplona - Iruña Teléfonos: 948169820 - 636741752 ")]),_c('a',{staticClass:"text-left white--text",attrs:{"href":"mailto:aulexna@aulexna.org"}},[_vm._v("aulexna@aulexna.org")])],1)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-list',{staticClass:"text-left",attrs:{"dark":"","color":"transparent","rounded":""}},[_c('v-list-item-group',{attrs:{"active-class":"text--accent"}},_vm._l((_vm.menuToShow),function(item){return _c('v-list-item',{key:item.uuid,attrs:{"to":{ name: item.pathName }}},[_c('v-list-item-title',[_c('router-link',{staticClass:"subtitle-2 text-uppercase white--text",class:{
                                                    'surface--text':
                                                        item.destacable
                                                },attrs:{"to":{
                                                    name: item.pathName
                                                }}},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"alt":"UPNA","src":require('./assets/images/Logo_UPNA_white.png'),"width":"50%"}})],1)],1)],1)],1),_c('v-card-text',{staticClass:"text-right secondary--text"},[_c('span',{staticClass:"mr-n7 text-caption"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" © Develop with "),_c('v-icon',{attrs:{"color":"secondary","x-small":""}},[_vm._v("mdi-heart-outline")]),_vm._v(" by "),_c('a',{staticClass:"secondary--text text--lighten-1",attrs:{"href":"https://www.ermetics.com"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("ermetics.com")])])],1)])],1)],1),_c('cookie-consent',_vm._b({scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('section',{staticClass:"mt-5 mb-4"},[_c('h2',{staticClass:"mb-6"},[_vm._v("Aviso de cookies")]),_c('v-row',{attrs:{"justify":"center","align":"start","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('span',{staticClass:"text-justify",domProps:{"innerHTML":_vm._s(_vm.cookies.message)}})])],1)],1)]},proxy:true}])},'cookie-consent',_vm.cookies,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }