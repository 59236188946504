import { TursoModel } from '@/models/tursoModel';

export class TursoController {
    static async selectAllFrom(tableName) {
        return await TursoModel.selectAllFrom(tableName);
    }

    static async selectByArgsFrom(tableName, ...args) {
        return await TursoModel.selectByArgsFrom(tableName, ...args);
    }

    static async insertInto(tableName, columns, values) {
        return await TursoModel.insertInto(tableName, columns, values);
    }

    static async update(tableName, columns, values, id) {
        return await TursoModel.update(tableName, columns, values, id);
    }

    static async deleteFrom(tableName, id) {
        return await TursoModel.deleteFrom(tableName, id);
    }
}
