import { createClient } from '@libsql/client';

const turso = createClient({
    url: process.env.VUE_APP_TURSO_DATABASE_URL,
    authToken: process.env.VUE_APP_TURSO_AUTH_TOKEN
});

export class TursoModel {
    static async selectAllFrom(tableName) {
        try {
            const { rows } = await turso.execute(`SELECT * FROM ${tableName}`);

            return rows;
        } catch (error) {
            console.error(error);
        }
    }

    static async selectByArgsFrom(tableName, ...args) {
        try {
            const whereClause =
                args.map((arg) => `${arg?.field} = ?`).join(' AND ') ?? '';

            const whereValues = args.map((arg) => arg?.value) ?? [];

            const { rows } = await turso.execute({
                sql: `SELECT * FROM ${tableName} WHERE ${whereClause}`,
                args: whereValues
            });

            return rows;
        } catch (error) {
            console.error(error);
        }
    }

    static async insertInto(tableName, columns, values) {
        try {
            const columnsNames = columns.join(', ');
            const columnsClause = values.map(() => '?').join(', ');

            const { rowsAffected } = await turso.execute({
                sql: `INSERT INTO ${tableName} (${columnsNames}) VALUES (${columnsClause})`,
                args: values
            });

            if (rowsAffected > 0) {
                return {
                    success: true,
                    message: 'Item añadido correctamente'
                };
            }

            return {
                success: false,
                message: 'Error al añadir item'
            };
        } catch (error) {
            console.error(error);
        }
    }

    static async update(tableName, columns, values, id) {
        try {
            const setClause = columns
                .map((column) => `${column} = ?`)
                .join(', ');

            const { rowsAffected } = await turso.execute({
                sql: `UPDATE ${tableName} SET ${setClause} WHERE ID = ?`,
                args: [...values, id]
            });

            if (rowsAffected > 0) {
                return {
                    success: true,
                    message: 'Item actualizado correctamente'
                };
            }

            return {
                success: false,
                message: 'Error al actualizar item'
            };
        } catch (error) {
            console.error(error);
        }
    }

    static async deleteFrom(tableName, id) {
        try {
            const { rowsAffected } = await turso.execute({
                sql: `DELETE FROM ${tableName} WHERE ID = ?`,
                args: [id]
            });

            if (rowsAffected > 0) {
                return {
                    success: true,
                    message: 'Item eliminado correctamente'
                };
            }

            return {
                success: false,
                message: 'Error al eliminar item'
            };
        } catch (error) {
            console.error(error);
        }
    }
}
