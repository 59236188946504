import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const customLightTheme = {
    dark: false,

    colors: {
        background: '#15202b',
        accent: '#fdc743',
        coconut: '#d6cebf',
        coconut2: '#796b60',
        primary: '#193154',
        secondary: '#d6d1bf',
        error: '#db1417',
        info: '#03b3e4',
        success: '#4db376',
        warning: '#ed6b1c',
        surface: '#c2763b'
    }
};

export default new Vuetify({
    theme: {
        themes: {
            light: { ...customLightTheme?.colors }
        }
    }
});
