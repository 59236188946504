<template>
    <div class="home-view">
        <carousel autoplay :autoplay-timeout="3500" :per-page="1">
            <slide v-for="{ src, uuid } in imagesFiltered" :key="uuid">
                <template v-if="processDev">
                    <v-img
                        width="100%"
                        max-width="100vw"
                        height="auto"
                        :src="require(`../../assets/banner/${src}`)"
                    />
                </template>
                <template v-else>
                    <v-img
                        width="100%"
                        max-width="100vw"
                        height="auto"
                        :src="`https://aulexna.org/assets/banner/${src}`"
                    />
                </template>
            </slide>
        </carousel>

        <cm-section title="Aulexna" :with-image="false">
            <template #content>
                <p>
                    <span class="font-weight-medium"
                        >Aulexna, Asociación del alumnado y exalumnado de la
                        Diplomatura del Aula de la Experiencia de la UPNA.</span
                    >
                    Somos una Asociación Cultural creada en el año 2005 con la
                    finalidad de ofertar cursos al alumnado que previamente hubo
                    finalizado el programa del Aula de la Experiencia de la
                    UPNA. Los Cursos de Extensión continúan siendo el eje y el
                    núcleo de nuestra actividad. Actualmente programamos más de
                    10 cursos impartidos en aulas de la UPNA y una rica y
                    variada oferta de actividades y talleres que hacen de
                    nuestra Asociación un espacio de aprendizaje, interacción
                    social y participación, abierto a nuestra comunidad.
                </p>

                <v-divider class="my-10" />

                <p class="font-italic">
                    Aulexna, NUPeko Esperientzia Gelako Diplomaturaren ikasle
                    eta ikasle ohien elkartea da. 2005ean sortutako Kultur
                    Elkartea gara, eta NUPeko Esperientzia Gela programa bukatu
                    duten ikasleei ikastaroak antolatzea du helburu. Zabaltze
                    Ikastaroek gure jardueraren ardatz eta muina izaten
                    jarraitzen dute. Gaur egun, 10 ikastaro baino gehiago
                    antolatzen ditugu NUPeko ikasgeletan baita jarduera eta
                    tailerren eskaintza aberatsa eta askotarikoa ere; gure
                    Elkartea ikasteko, gizartean elkarreraginean aritzeko eta
                    parte hartzeko gunea izan dadin, komunitateari irekia.
                </p>
            </template>
        </cm-section>

        <cm-section
            title="¿Qué hacemos? / Zer egiten dugu?"
            :with-image="false"
        >
            <template #content>
                <v-row class="mt-0" justify="start" align="start">
                    <v-col
                        cols="12"
                        xl="4"
                        lg="4"
                        md="6"
                        v-for="card in cards"
                        :key="card.uuid"
                    >
                        <v-hover v-slot="{ hover }">
                            <v-card
                                class="mx-0"
                                :elevation="hover ? 18 : 6"
                                max-width="400"
                                :color="card.color"
                                :dark="card.dark"
                            >
                                <v-img
                                    :src="
                                        require(`../../assets/images/${card.image}`)
                                    "
                                    height="200px"
                                >
                                    <v-card-title
                                        class="text-h5 font-weight-black"
                                        style="background-color: #19315499"
                                        >{{ card.title }}</v-card-title
                                    >
                                </v-img>

                                <v-card-text>
                                    <p class="body-1 text-justify">
                                        {{ card.text }}
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </template>
        </cm-section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TursoController } from '@/controllers/tursoController';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'HomeView',

    data: () => ({
        cards: [
            {
                uuid: uuidv4(),
                image: 'arrosadia.jpg',
                title: 'Áula de la experiencia',
                text: 'El Aula de la Experiencia de la UPNA ofrece desde hace 20 años a los mayores de 50 años el Diploma de Humanidades y Ciencias Sociales que se imparte en cuatro cursos en el Campus de Arrosadia.',
                color: 'primary',
                dark: true
            },
            {
                uuid: uuidv4(),
                image: 'image10.jpg',
                title: 'Cursos de extensión',
                text: 'Cursos culturales organizados por Aulexna y dirigidos al exalumnado del Aula de la experiencia. Fueron la razón de la creación de la asociación y continúan constituyendo el eje vertebral de su actividad. Con ellos pretendemos crear un espacio de aprendizaje y de desarrollo del espíritu crítico para fomentar una integración informada y crítica en nuestra sociedad de nuestros asociados/as.',
                color: 'primary',
                dark: true
            },
            {
                uuid: uuidv4(),
                image: 'visita_cultural.jpg',
                title: 'Actividades y talleres',
                text: 'Organizamos variadas actividades de senderismo, salidas culturales, cinefórum, ópera, conferencias y talleres de teatro, coro, clubs de lectura informática con la finalidad de crear espacios de convivencia y participación en los que compartir experiencias, conocimiento y emociones.',
                color: 'primary',
                dark: true
            }
        ],

        images: []
    }),

    methods: {
        requireDevImageContext() {
            const req = require.context(
                '../../assets/banner/',
                false,
                /\.(png|jpe?g|svg)$/
            );

            this.imageItemConstructor(req.keys());
        },

        requireProdImageContext() {
            TursoController.selectAllFrom('recursos').then((images) => {
                this.imageItemConstructor(
                    images
                        .filter((e) => e.entidad === 'banner')
                        .map((e) => e.recurso)
                );
            });
        },

        haveImageExpiredDate(caducaEn) {
            return caducaEn.replace(/\.(png|jpe?g|svg)$/, '') !== 'no_caduca';
        },

        async imageItemConstructor(images = []) {
            images.forEach((uri) => {
                const uriReplaced = uri.replace('./', '');
                const [, title, caduca_en] = uriReplaced.split('__');
                let caducaEn = null;
                let caducaEnJoined = null;
                let year = null;
                let month = null;
                let day = null;

                if (this.haveImageExpiredDate(caduca_en)) {
                    caducaEn = caduca_en.replace(/\.(png|jpe?g|svg)$/, '');
                    year = caducaEn.substring(0, 4);
                    month = caducaEn.substring(4, 6);
                    day = caducaEn.substring(6, 8);
                    caducaEnJoined = [year, month, day].join('/');
                }

                this.images.push({
                    uuid: uuidv4(),
                    src: uriReplaced,
                    title: title
                        .replace(/\.(png|jpe?g|svg)$/, '')
                        .replace(/\+/, ' / ')
                        .split('_')
                        .join(' '),
                    text: '',
                    caduca_en: caducaEnJoined
                });
            });
        },

        fetchImages() {
            this.processDev
                ? this.requireDevImageContext()
                : this.requireProdImageContext();
        },

        onload() {
            this.fetchImages();
        }
    },

    computed: {
        ...mapGetters(['processDev']),
        imagesFiltered() {
            return this.images.filter(
                (e) =>
                    e.caduca_en === null ||
                    new Date().getTime() <=
                        new Date(`${e.caduca_en} 00:00:01`).getTime()
            );
        }
    },

    mounted() {
        this.onload();
    }
};
</script>
