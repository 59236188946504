<template>
    <section>
        <v-container fluid>
            <v-row v-if="withImage" justify="center" align="start" no-gutters>
                <v-col cols="11">
                    <template v-if="$vuetify.breakpoint.mobile">
                        <section class="text-h6 mt-4 py-3 coconut rounded-xl">
                            <span class="text-title font-weight-bold">
                                {{ title.split(' / ')[0] }} /
                            </span>

                            <br />

                            <span class="text-title font-weight-bold">
                                {{ title.split(' / ')[1] }}
                            </span>
                        </section>
                    </template>

                    <template v-else>
                        <v-parallax
                            :src="require(`../../assets/images/${imageFile}`)"
                            :alt="imageAltName"
                            height="90"
                            class="rounded-xl"
                            dark
                        >
                            <v-row
                                align="end"
                                justify="start"
                                style="
                                    background-color: #19315496;
                                    margin-left: -16px;
                                "
                            >
                                <v-col
                                    style="background-color: #193154"
                                    cols="auto"
                                >
                                    <span
                                        :class="`text-${
                                            breakpoint < 4 ? 'h6' : 'h4'
                                        } text-right font-weight-bold`"
                                        style="padding-left: 130px"
                                    >
                                        {{ title }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-parallax>
                    </template>
                </v-col>
            </v-row>

            <v-row justify="center" align="start">
                <v-col cols="12" sm="11" class="px-5 py-10">
                    <v-row justify="start" align="start" class="text-justify">
                        <v-col v-if="!withImage" cols="12">
                            <span
                                :class="`text-${
                                    breakpoint < 4 ? 'h5' : 'h3'
                                } surface--text text-left`"
                            >
                                {{ title }}
                            </span>
                        </v-col>

                        <v-col cols="12" class="text-custom">
                            <slot name="content">
                                <p>
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Odit fugiat consequatur
                                    dicta repellat iste ipsum incidunt ex quasi
                                    explicabo adipisci. Amet labore odio aliquam
                                    incidunt eaque soluta dignissimos, earum
                                    pariatur! Lorem ipsum dolor sit amet,
                                    consectetur adipisicing elit. Cum,
                                    praesentium sint recusandae possimus, sed
                                    voluptas corrupti nisi similique placeat non
                                    cumque rem dicta quam dolorem alias sapiente
                                    fugit dolore in.
                                </p>

                                <p>
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Aperiam ut reiciendis
                                    aliquid eum cupiditate facere? Rerum culpa
                                    aliquam tenetur dolorem ea placeat
                                    architecto nobis temporibus incidunt? Itaque
                                    nobis iure sunt. Lorem ipsum dolor sit amet
                                    consectetur adipisicing elit. Eius
                                    consectetur praesentium ipsum quidem dolores
                                    doloremque unde molestiae, qui itaque eos,
                                    illo cum. Facere veniam mollitia quasi
                                    assumenda illo pariatur magnam! Lorem ipsum
                                    dolor, sit amet consectetur adipisicing
                                    elit. Unde quaerat eius tempora provident
                                    natus veniam consequatur quidem possimus
                                    dignissimos? Rerum, nostrum? Laborum nihil
                                    esse ut odio explicabo in porro molestias?
                                </p>

                                <p>
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Odit fugiat consequatur
                                    dicta repellat iste ipsum incidunt ex quasi
                                    explicabo adipisci. Amet labore odio aliquam
                                    incidunt eaque soluta dignissimos, earum
                                    pariatur! Lorem ipsum dolor sit amet,
                                    consectetur adipisicing elit. Cum,
                                    praesentium sint recusandae possimus, sed
                                    voluptas corrupti nisi similique placeat non
                                    cumque rem dicta quam dolorem alias sapiente
                                    fugit dolore in.
                                </p>

                                <p>
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Odit fugiat consequatur
                                    dicta repellat iste ipsum incidunt ex quasi
                                    explicabo adipisci. Amet labore odio aliquam
                                    incidunt eaque soluta dignissimos, earum
                                    pariatur! Lorem ipsum dolor sit amet,
                                    consectetur adipisicing elit. Cum,
                                    praesentium sint recusandae possimus, sed
                                    voluptas corrupti nisi similique placeat non
                                    cumque rem dicta quam dolorem alias sapiente
                                    fugit dolore in.
                                </p>
                            </slot>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: 'CmSection',

    props: {
        imageFile: {
            type: String,
            default: 'arrosadia.jpg'
        },

        imageAltName: {
            type: String,
            default: 'Campus UPNA'
        },

        title: {
            type: String,
            default: 'Title...'
        },

        withImage: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        breakpoint() {
            const breakpoints = {
                xs: 1,
                sm: 2,
                md: 3,
                lg: 4,
                xl: 5
            };

            return breakpoints[this.$vuetify.breakpoint.name];
        }
    }
};
</script>

<style scoped>
.text-custom {
    font-size: 1.3rem;
}
</style>
