<template>
    <v-btn
        block
        color="accent"
        class="primary--text"
        :disabled="disabled"
        @click="$emit('click:btn', $event)"
        >{{ label }}</v-btn
    >
</template>

<script>
export default {
    name: 'BtnPrimary',

    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        label: {
            type: String,
            required: true,
            default: 'Label...'
        }
    }
};
</script>
