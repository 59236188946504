import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router/index.js';

import { TursoController } from '../controllers/tursoController.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        dataFromAPI: {
            actividades_talleres: [],
            cursos_extension: [],
            enlaces_interes: [],
            galeria_imagenes: [],
            junta_directiva: [],
            plan_actividades: []
        },

        socioAuth: false,
        isUserAdmin: false
    },

    getters: {
        socioAuth: (state) => state.socioAuth,
        isUserAdmin: (state) => state.isUserAdmin,

        juntaDirectiva: (state) =>
            state.dataFromAPI.junta_directiva.map((miembro) => ({
                apellidos: miembro.apellidos,
                cargo: miembro.cargo,
                id: miembro.ID,
                nombre: miembro.nombre,
                nombreCompleto: `${miembro.nombre} ${miembro.apellidos}`
            })),

        cursosExtension: (state) =>
            state.dataFromAPI.cursos_extension.map((curso) => ({
                fecha: curso.fecha ?? '',
                horario: curso.horario ?? '',
                id: curso.id,
                imparte: curso.imparte.split(',') ?? [],
                meta: { name: curso.meta_name.split(',') ?? [] },
                objetivos: curso.objetivos ?? '',
                precio: curso.precio ?? '',
                tag: curso.tag,
                text: curso.text ?? '',
                titulo: curso.titulo ?? ''
            })),

        processDev: () => process.env.NODE_ENV === 'development',
        processPro: () => process.env.NODE_ENV === 'production'
    },

    mutations: {
        MUTATE(state, { prop, value }) {
            state[prop] = value;
        }
    },

    actions: {
        // API CRUD actions
        async addItem({ commit, state }, { table, columns, values }) {
            await TursoController.insertInto(table, columns, values)
                .then(async ({ message, success }) => {
                    if (success) {
                        commit('MUTATE', {
                            prop: 'dataFromAPI',
                            value: {
                                ...state.dataFromAPI,
                                [table]: await TursoController.selectAllFrom(
                                    table
                                )
                            }
                        });
                    } else {
                        throw new Error(message);
                    }
                })
                .catch((error) => {
                    alert(error);
                });
        },

        async updateItem({ commit, state }, { table, columns, values, id }) {
            await TursoController.update(table, columns, values, id)
                .then(async ({ message, success }) => {
                    if (success) {
                        commit('MUTATE', {
                            prop: 'dataFromAPI',
                            value: {
                                ...state.dataFromAPI,
                                [table]: await TursoController.selectAllFrom(
                                    table
                                )
                            }
                        });
                    } else {
                        throw new Error(message);
                    }
                })
                .catch((error) => {
                    alert(error);
                });
        },

        async deleteItem({ commit, state }, { table, id }) {
            await TursoController.deleteFrom(table, id)
                .then(async ({ message, success }) => {
                    if (success) {
                        commit('MUTATE', {
                            prop: 'dataFromAPI',
                            value: {
                                ...state.dataFromAPI,
                                [table]: await TursoController.selectAllFrom(
                                    table
                                )
                            }
                        });
                    } else {
                        throw new Error(message);
                    }
                })
                .catch((error) => {
                    alert(error);
                });
        },

        loginUser({ commit, dispatch }, { value }) {
            dispatch('getDataFromAPI', { commit })
                .then(() => {
                    commit('MUTATE', { prop: 'socioAuth', value: true });
                    commit('MUTATE', { prop: 'isUserAdmin', value });
                })
                .then(() => router.replace({ name: 'DocumentacionPvtView' }))
                .catch((error) => console.error(error));
        },

        async getDataFromAPI({ commit, state }) {
            commit('MUTATE', {
                prop: 'dataFromAPI',
                value: {
                    ...state.dataFromAPI,
                    junta_directiva: await TursoController.selectAllFrom(
                        'junta_directiva'
                    ),
                    cursos_extension: await TursoController.selectAllFrom(
                        'cursos_extension'
                    )
                }
            });
        },

        mutateState({ commit }, { prop, value }) {
            commit('MUTATE', { prop, value });
        }
    }
});
