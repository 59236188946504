<template>
    <v-app id="app">
        <v-app-bar
            app
            :height="$store.getters.socioAuth ? 80 : 120"
            :color="$store.getters.socioAuth ? 'primary darken-2' : 'coconut'"
        >
            <v-container fluid>
                <v-row justify="center" align="center">
                    <!--mobile-->
                    <template v-if="$vuetify.breakpoint.mobile">
                        <v-col cols="auto">
                            <router-link
                                class="mx-2"
                                active-class=""
                                :to="{ name: 'HomeView' }"
                            >
                                <component
                                    :is="
                                        $store.getters.socioAuth
                                            ? 'logo-aulexna-socios'
                                            : 'logo-aulexna-tertiary'
                                    "
                                />
                            </router-link>
                        </v-col>

                        <v-spacer />

                        <v-col cols="auto">
                            <v-btn
                                :dark="$store.getters.socioAuth"
                                icon
                                x-large
                                @click="drawer = !drawer"
                            >
                                <v-icon large>mdi-menu</v-icon>
                            </v-btn>
                        </v-col>
                    </template>

                    <!--desktop-->
                    <template v-else>
                        <v-col cols="auto">
                            <router-link
                                class="mx-5"
                                :to="{ name: 'HomeView' }"
                            >
                                <component
                                    :is="
                                        $store.getters.socioAuth
                                            ? 'logo-aulexna-socios'
                                            : 'logo-aulexna-tertiary'
                                    "
                                />
                            </router-link>
                        </v-col>

                        <v-spacer />

                        <v-col cols="auto">
                            <template v-if="$store.getters.socioAuth">
                                <a
                                    class="h6 text-center surface--text"
                                    href="https://m.berrly.com/aulexna/login"
                                    target="_blank"
                                    >ZONA PRIVADA</a
                                >
                                <router-link
                                    class="mx-5 white--text"
                                    :to="{ name: 'HomeView' }"
                                >
                                    <v-icon
                                        class="mr-1"
                                        color="white"
                                        size="1.2rem"
                                        style="margin-top: -2.5px"
                                        >mdi-exit-to-app</v-icon
                                    >
                                    <span
                                        class="text-uppercase font-weight-regular"
                                        >SALIR</span
                                    >
                                </router-link>
                            </template>

                            <template v-else>
                                <router-link
                                    v-for="item in menuToShow"
                                    :key="item.uuid"
                                    class="mx-3 black--text"
                                    :class="{
                                        'surface--text': item.destacable
                                    }"
                                    :to="{ name: item.pathName }"
                                >
                                    <span
                                        class="text-uppercase font-weight-regular"
                                        style="font-size: 1.1rem"
                                        >{{ item.title }}</span
                                    >
                                </router-link>
                            </template>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </v-app-bar>

        <!--drawer mobile device-->
        <v-navigation-drawer
            v-model="drawer"
            app
            dark
            color="primary"
            temporary
            width="80%"
            mobile-breakpoint="600"
        >
            <v-list>
                <v-list-item-group active-class="info--text text--accent-4">
                    <v-list-item v-for="item in menuToShow" :key="item.uuid">
                        <v-list-item-title>
                            <router-link
                                class="h6 white--text"
                                :class="{ 'surface--text': item.destacable }"
                                :to="{ name: item.pathName }"
                                >{{ item.title }}</router-link
                            >
                        </v-list-item-title>
                    </v-list-item>

                    <template v-if="this.$store.getters.socioAuth">
                        <v-list-item>
                            <v-list-item-title>
                                <a
                                    class="h6 text-center surface--text"
                                    href="https://m.berrly.com/aulexna/login"
                                    target="_blank"
                                    >Zona privada</a
                                >
                            </v-list-item-title>
                        </v-list-item>
                    </template>

                    <template v-if="this.$store.getters.socioAuth">
                        <v-list-item>
                            <v-list-item-title>
                                <router-link
                                    class="h6 text-center surface--text"
                                    :to="{ name: 'HomeView' }"
                                    >Salir</router-link
                                >
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <!--main view-->
        <v-main class="secondary lighten-2">
            <!--private-->
            <template
                v-if="$store.getters.socioAuth && !$vuetify.breakpoint.mobile"
            >
                <v-container class="pa-0 fill-height" fluid>
                    <v-row align="start" justify="start">
                        <v-col cols="2">
                            <v-navigation-drawer
                                v-model="$store.getters.socioAuth"
                                absolute
                                clipped
                                left
                                color="secondary"
                            >
                                <v-list>
                                    <v-list-item-group
                                        active-class="primary--text"
                                    >
                                        <v-list-item
                                            v-for="item in menuItemsPrivados.filter(
                                                (e) => !e.destacable
                                            )"
                                            :key="item.uuid"
                                            :to="{ name: item.pathName }"
                                            :class="{
                                                'background lighten-3':
                                                    item.destacable
                                            }"
                                        >
                                            <v-list-item-avatar class="mr-2">
                                                <v-icon
                                                    size="1.3rem"
                                                    color="primary"
                                                    >{{ item.icon }}</v-icon
                                                >
                                            </v-list-item-avatar>

                                            <v-list-item-title
                                                class="text-left"
                                            >
                                                <router-link
                                                    class="h6"
                                                    :class="{
                                                        'white--text':
                                                            item.destacable
                                                    }"
                                                    :to="{
                                                        name: item.pathName
                                                    }"
                                                    >{{
                                                        item.title
                                                    }}</router-link
                                                >
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-navigation-drawer>
                        </v-col>

                        <v-col cols="10">
                            <router-view />
                        </v-col>
                    </v-row>
                </v-container>
            </template>

            <!--public & private mobile-->
            <template v-else>
                <v-container
                    :class="{
                        'pa-0':
                            $route.name === 'HomeView' ||
                            $vuetify.breakpoint.mobile
                    }"
                    fluid
                >
                    <router-view />
                </v-container>
            </template>
        </v-main>

        <!--footer-->
        <v-footer padless>
            <v-card flat tile width="100%" class="px-10 primary text-center">
                <v-card-text>
                    <v-container fluid>
                        <v-row justify="start" align="center">
                            <v-col cols="12" md="4">
                                <div class="d-flex flex-column">
                                    <logo-aulexna-secondary />

                                    <p class="text-left white--text">
                                        C/. Sadar, s/n. Edif. El Sario (UPNA).
                                        Ofc. 231 31006 Pamplona - Iruña
                                        Teléfonos: 948169820 - 636741752
                                    </p>

                                    <a
                                        class="text-left white--text"
                                        href="mailto:aulexna@aulexna.org"
                                        >aulexna@aulexna.org</a
                                    >
                                </div>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-list
                                    dark
                                    class="text-left"
                                    color="transparent"
                                    rounded
                                >
                                    <v-list-item-group
                                        active-class="text--accent"
                                    >
                                        <v-list-item
                                            v-for="item in menuToShow"
                                            :key="item.uuid"
                                            :to="{ name: item.pathName }"
                                        >
                                            <v-list-item-title>
                                                <router-link
                                                    class="subtitle-2 text-uppercase white--text"
                                                    :class="{
                                                        'surface--text':
                                                            item.destacable
                                                    }"
                                                    :to="{
                                                        name: item.pathName
                                                    }"
                                                    >{{
                                                        item.title
                                                    }}</router-link
                                                >
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-img
                                    class="mx-auto"
                                    alt="UPNA"
                                    :src="
                                        require('./assets/images/Logo_UPNA_white.png')
                                    "
                                    width="50%"
                                ></v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-text class="text-right secondary--text">
                    <span class="mr-n7 text-caption">
                        {{ new Date().getFullYear() }} &copy; Develop with
                        <v-icon color="secondary" x-small
                            >mdi-heart-outline</v-icon
                        >

                        by
                        <a
                            class="secondary--text text--lighten-1"
                            href="https://www.ermetics.com"
                            ><span class="font-weight-bold"
                                >ermetics.com</span
                            ></a
                        ></span
                    >
                </v-card-text>
            </v-card>
        </v-footer>

        <!--cookies advertiment-->
        <cookie-consent v-bind="cookies">
            <template #message>
                <section class="mt-5 mb-4">
                    <h2 class="mb-6">Aviso de cookies</h2>
                    <v-row justify="center" align="start" no-gutters>
                        <v-col cols="12" lg="10">
                            <span
                                class="text-justify"
                                v-html="cookies.message"
                            ></span>
                        </v-col>
                    </v-row>
                </section>
            </template>
        </cookie-consent>
    </v-app>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'App',

    components: {
        LogoAulexnaPrimary: () =>
            import('@/components/logos/LogoAulexnaPrimary.vue'),

        LogoAulexnaSecondary: () =>
            import('@/components/logos/LogoAulexnaSecondary.vue'),

        LogoAulexnaTertiary: () =>
            import('@/components/logos/LogoAulexnaTertiary.vue'),

        LogoAulexnaSocios: () =>
            import('@/components/logos/LogoAulexnaSocios.vue')
    },

    data: () => ({
        drawer: false,

        menuItemsPublicos: [
            {
                uuid: uuidv4(),
                title: 'Inicio',
                pathName: 'HomeView',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: 'Aulexna',
                pathName: 'AboutView',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: 'Contacto',
                pathName: 'ContactoView',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: '¡Hazte socio/a!',
                pathName: 'HazteSocioView',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: 'Área del socio/a',
                pathName: 'AreaSocioView',
                destacable: true
            }
        ],

        menuItemsPrivados: [
            {
                uuid: uuidv4(),
                title: 'La organización',
                pathName: 'DocumentacionPvtView',
                icon: 'mdi-account-group',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: 'Cursos extensión',
                pathName: 'CursosPvtView',
                icon: 'mdi-grease-pencil',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: 'Actividades y talleres',
                pathName: 'ActividadesTalleresPvtView',
                icon: 'mdi-lightbulb-outline',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: 'Galería de imágenes',
                pathName: 'GaleriaPvtView',
                icon: 'mdi-camera-image',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: 'Plan Activ. mensual',
                pathName: 'PlanActividadesMensualesPvtView',
                icon: 'mdi-calendar-month',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: 'Enlaces interés',
                pathName: 'EnlacesInteresPvtView',
                icon: 'mdi-link',
                destacable: false
            },
            {
                uuid: uuidv4(),
                title: 'Contacto socio/a',
                pathName: 'ContactoSocioPvtView',
                icon: 'mdi-contacts',
                destacable: false
            }
        ],

        cookies: {
            buttonLabel: 'Estoy de acuerdo',
            message: `
                <p>Utilizamos cookies propias y de terceros así como el almacenamiento local del navegador (localStorage) para analizar nuestros servicios y ofrecerte la mejor experiencia de usuario.</p>
                <p>Una cookie es un pequeño archivo de información que se guarda en tu ordenador, smartphone o tableta cada vez que visitas nuestra página web. Algunas pueden ser nuestras y otras pertenecen a empresas externas que prestan servicios para nuestra página web. <a href="http://cookiesandyou.com" style="text-decoration: underline; color:white;" target="_blank">¿Qué es una cookie?</a></p>`
        }
    }),

    computed: {
        menuToShow() {
            return this.$store.getters.socioAuth
                ? this.menuItemsPrivados
                : this.menuItemsPublicos;
        }
    }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200;400;500;900&display=swap');
@import '../node_modules/vue-cookieconsent-component/src/scss/cookie-consent';
@import '../node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom';
@import '../node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition';

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

a {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a.router-link-active {
    font-weight: bold;
    color: #2c3e50;
}

a.router-link-exact-active {
    font-weight: bold;
    color: #42b983;
}

div.cookie-consent {
    background: url('../src/assets/images/cookies-pattern.jpg');
    background-color: #0c1a2e;
    background-blend-mode: multiply;
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    color: #fdc743;
    font-size: large;
}
</style>
