import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './registerServiceWorker';

// Components globally
import BtnPrimary from './components/buttons/BtnPrimary.vue';
import CmSection from './components/views/CmSection.vue';
import cookieconsent from 'vue-cookieconsent-component';
import VueCarousel from 'vue-carousel';

// Mixins
import { miscelanea } from './shared/mixins/miscelanea.js';

Vue.component('BtnPrimary', BtnPrimary);
Vue.component('CmSection', CmSection);
Vue.component('cookie-consent', cookieconsent);
Vue.mixin(miscelanea);

Vue.config.productionTip = false;
Vue.use(VueCarousel);

new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
